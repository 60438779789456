import {postAsync, getAsync, debounce} from "./utils";
import {timeline, data, layers, map} from "./common";
import rangeSlider from "rangeslider-pure";
import * as d3 from "d3";
import * as dayjs from "dayjs";
import {
  displayWaves,
  hideWaves,
  displayWindForecasts,
  displayWavesForecasts,
  renderWindSensors,
  displayForecastDetails, displayPOIs, hideTemperatures, displayTemperatures
} from "./data";
import {config} from "./config";

export function initTimeline(timestamp, initCallback) {
  const today = new Date(new Date().toDateString()).getTime() / 1000, offset = today - timestamp;
  const timestampPath = offset ? (offset > 0 ? `-m-${offset}` : `-p-${-offset}`) : '';

  getAsync(`https://private2.windmorbihan.com/mesures/get_time_line_data${timestampPath}.json`, function(resp) {
    // 600 seconds steps x 144 = 24h
    var i, dayWrapper = document.querySelector("#timeline_date");
    if (timeline.timelineReference !== timestamp) {
      timeline.timelineReference = timestamp;
      timeline.timelineSteps = [];
      for (i = 0; i < 144; i++) {
        timeline.timelineSteps.push([timestamp + (i * timeline.timelineStep), timestamp + ((i + 1) * timeline.timelineStep)]);
      }
    }

    try {
      parseTimelineData(timestamp, resp);
    } catch (ex) {
      console.error("invalid resp for timeline data");
    }
    initMobileTimeline();
    renderTimeline(timestamp);
    if (!timeline.setUp) {
      setUpKeyboardActions();
    }
    dayWrapper.innerHTML = dayjs.unix(timestamp).format("ddd DD/MM");
    if (initCallback) {
      initCallback();
    }
  });
}

function renderTimeline(referenceDate) {
  var hourWrapper = document.querySelector("#timeline_hour"), staticSensors, ts, prevTimestamp, i;
  var timelineItems = d3.select("#timeline_content").selectAll(".tl_item").data(timeline.timelineSteps).join("div")
    .attr("class", function(step) {
      return "tl_item" + ((timeline.timelineActiveStep && d3.select(timeline.timelineActiveStep).datum()[0] === step[0]) ? ' active' : '') +
        (data.timelineData[step[0]] ? ' tl_obs' : '');
    })
    .attr("data-ts", function(step) { return step[0]; })
    .attr("data-rt", function(step) { return data.timelineData[step[0]] ? '1' : null; })
    .attr("data-fc", function(step) { return (step[0] % 3600 === 0) ? (((step[0] - referenceDate) / 3600) + 'h') : null; })
    .on("mouseover", function(evt, d) {
      prevTimestamp = hourWrapper.innerHTML;
      ts = d[0];
      staticSensors = document.querySelectorAll("#markers_layer .wave_sensor, #markers_layer .temp_sensor, #markers_layer .water_temp_sensor");
      displayTimelineItem(ts);
      if (staticSensors && staticSensors.length) {
        for (i = 0; i < staticSensors.length; i++) {
          staticSensors[i].style = "display: none;"
        }
      }
    })
    .on("mouseout", function(evt) {
      if (timeline.timelineActiveStep) {
        ts = d3.select(timeline.timelineActiveStep).datum()[0];
        displayTimelineItem(ts);
      } else {
        hourWrapper.innerHTML = prevTimestamp;
        if (layers.dataLayers.indexOf('wind') !== -1) {
          renderWindSensors(data.windData);
        }
        if (staticSensors && staticSensors.length) {
          for (i = 0; i < staticSensors.length; i++) {
            staticSensors[i].style = ""
          }
        }
      }
    })
    .on("click", function(evt) {
      toggleStepTimeline(evt.target);
      let isFutur = !evt.target.classList.contains("tl_obs");
      if (isFutur) {
        if (layers.dataLayers.indexOf('wind_fc') === -1) {
          var menuBtn = document.querySelector("#wind_fc");
          wm.toggleLayer(menuBtn, 'wind_fc');
        }
      }
    });
  if (!timeline.timelineActiveStep) {
    hourWrapper.innerHTML = dayjs.unix(new Date().getTime() / 1000).format("HH[h]mm");
  }
}

function displayTimelineItem(ts) {
  var itemData = data.timelineData[ts] || {};
  document.querySelector("#timeline_hour").innerHTML = dayjs.unix(ts).format("HH[h]mm");
  renderWindSensors(Object.values(itemData));
}

function parseTimelineData(timestamp, rawData) {
  data.timelineData = {};
  var currentTs;
  for (let nid in rawData) {
    if (rawData.hasOwnProperty(nid)) {
      for (let ts in rawData[nid]) {
        if (rawData[nid].hasOwnProperty(ts)) {
          currentTs = timestamp + parseInt(ts);
          for (let s of timeline.timelineSteps) {
            if (currentTs >= s[0] && currentTs < s[1]) {
              data.timelineData[s[0]] = data.timelineData[s[0]] || {}
              data.timelineData[s[0]][nid] = {
                nid: nid,
                ts: currentTs,
                wind_dir_true: rawData[nid][ts]['wind_dir_true'],
                wind_pow_knot: rawData[nid][ts]['wind_pow_knot'],
                wind_pow_knot_max: rawData[nid][ts]['wind_pow_knot_max']
              };
            }
          }
        }
      }
    }
  }
}

export function appendToTimeline(newMeasures) {
  var i, createdAt, referenceDate = new Date(new Date().toDateString()).getTime() / 1000;
  for (i = 0; i < newMeasures.length; i++) {
    createdAt = parseInt(Object.keys(newMeasures[i].created)[0]);
    for (let s of timeline.timelineSteps) {
      if (createdAt >= s[0] && createdAt < s[1]) {
        data.timelineData[s[0]] = data.timelineData[s[0]] || {}
        if (!data.timelineData[s[0]][newMeasures[i].nid]) {
          data.timelineData[s[0]][newMeasures[i].nid] = {
            nid: newMeasures[i].nid,
            ts: createdAt,
            wind_dir_true: newMeasures[i]['wind_dir_true'],
            wind_pow_knot: newMeasures[i]['wind_pow_knot'],
            wind_pow_knot_max: newMeasures[i]['wind_pow_knot_max']
          };
        }
      }
    }
  }
  renderTimeline(referenceDate);
  updateMobileTimeline();
}

function toggleStepTimeline(elt) {
  if (elt.getAttribute('data-rt') || elt.getAttribute('data-fc') || layers.dataLayers.indexOf('wind_fc') !== -1) {
    let activeItem = document.querySelector('#timeline_content .tl_item.active')
    if (activeItem) {
      activeItem.classList.remove('active')
    }
    elt.classList.add('active');
    timeline.timelineActiveStep = elt;
    if(elt.getAttribute('data-fc')){
      refreshDataLayers();
    }
  }
}

export function toggleTimeline(btn) {
  if (btn.classList.contains('playing') && timeline.timelineInterval) {
    btn.classList.remove('playing');
    btn.querySelector("i").classList.replace('fa-pause', 'fa-play');
    clearInterval(timeline.timelineInterval);
  } else {
    timeline.timelineActiveStep = timeline.timelineActiveStep || defaultActiveStep();
    btn.classList.add('playing');
    btn.querySelector("i").classList.replace('fa-play', 'fa-pause');
    timeline.timelineInterval = setInterval(function() {
      let tsItem = selectTsTimeline(selectedTs() + timeline.timelineStep, true);
      if (!tsItem) toggleTimeline(btn);
    }, 1200);
  }
}

function defaultActiveStep() {
  return Array.from(document.querySelectorAll("#timeline_content .tl_item.tl_obs")).pop() ||
    document.querySelector("#timeline_content .tl_item:first-child");
}

export function nextStepTimeline() {
  timeline.timelineActiveStep = timeline.timelineActiveStep || defaultActiveStep();
  var offset = timeline.timelineActiveStep.nextSibling.getAttribute('data-rt') ? timeline.timelineStep :
    (3600 - parseInt(timeline.timelineActiveStep.getAttribute('data-ts')) % 3600);
  selectTsTimeline(selectedTs() + offset, true);
}

export function prevStepTimeline() {
  timeline.timelineActiveStep = timeline.timelineActiveStep || defaultActiveStep();
  var offset = timeline.timelineActiveStep.previousSibling.getAttribute('data-rt') ? timeline.timelineStep :
    (3600 - parseInt(timeline.timelineActiveStep.getAttribute('data-ts')) % 3600);
  selectTsTimeline(selectedTs() - offset, true);
}

export function nextTimeline() {
  var nextDay = timeline.timelineReference + (3600 * 24), nextTs = nextDay + 12 * 3600;
  timeline.timelineActiveStep = null
  initTimeline(nextDay, function() {
    toggleStepTimeline(document.querySelector("#timeline_content .tl_item[data-ts='" + nextTs + "']"));
    displayTimelineItem(nextTs);
    updateMobileTimeline(nextTs);
  });
}

export function prevTimeline() {
  var prevDay = timeline.timelineReference - (3600 * 24), prevTs = prevDay + 12 * 3600;
  timeline.timelineActiveStep = null;
  initTimeline(prevDay, function() {
    toggleStepTimeline(document.querySelector("#timeline_content .tl_item[data-ts='" + prevTs + "']"));
    displayTimelineItem(prevTs);
    updateMobileTimeline(prevTs);
  });
}

export function refreshTimeline() {
  timeline.timelineActiveStep = null;
  initTimeline(new Date(new Date().toDateString()).getTime() / 1000, updateMobileTimeline);
  renderWindSensors(data.windData);
  refreshDataLayers();
}

function selectedTs() {
  return d3.select(timeline.timelineActiveStep).datum()[0];
}

function selectTsTimeline(newTs, updateMobile) {
  let newItem = document.querySelector("#timeline_content .tl_item[data-ts='" + newTs + "']");
  if (newItem) {
    toggleStepTimeline(newItem);
    document.querySelector("#timeline_hour").innerHTML = dayjs.unix(newTs).format("HH[h]mm");
    renderWindSensors(Object.values(data.timelineData[newTs] || {}));

    if (updateMobile) {
      updateMobileTimeline(newTs);
    }
  }
  return newItem;
}

export let refreshDataLayers = debounce(function() {
  var ts = null, rendered = document.querySelector("#map_container").classList.contains('rendered');
  if (timeline.timelineActiveStep) {
    ts = parseInt(timeline.timelineActiveStep.getAttribute('data-ts'));
    if (!timeline.timelineActiveStep.getAttribute('data-fc')) {
      ts = ts - (ts % 3600);
    }
    console.debug('ts : ' + ts);
  }

  if (layers.dataLayers.indexOf('wind_fc') !== -1){
    if(map.windLayer){
      map.windLayer.stop();
    }
    displayWindForecasts(ts).then(function () {
      if (map.currentFocus && config.popup) {
        displayForecastDetails(config.popup, map.currentFocus.lat, map.currentFocus.lng);
      }
    });
  }

  if (layers.dataLayers.indexOf('waves_fc') !== -1) {
    displayWavesForecasts(ts).then(function () {
      if (map.currentFocus && config.popup) {
        displayForecastDetails(config.popup, map.currentFocus.lat, map.currentFocus.lng);
      }
    });
  }

  if (layers.dataLayers.indexOf('weather_fc') !== -1) {
    displayPOIs(ts);
  }
  if (layers.dataLayers.indexOf('waves') !== -1) {
    if (timeline.timelineActiveStep) {
      hideWaves();
    } else {
      displayWaves();
    }
  }
  if (layers.dataLayers.indexOf('temp') !== -1) {
    if (timeline.timelineActiveStep) {
      hideTemperatures();
    } else {
      displayTemperatures();
    }
  }
}, 700);

function setUpKeyboardActions() {
  window.addEventListener("keydown", function(e) {
    switch(e.code) {
      case "ArrowLeft":
        e.stopPropagation();
        e.preventDefault();
        prevStepTimeline();
        break;
      case "ArrowRight":
        e.stopPropagation();
        e.preventDefault();
        nextStepTimeline();
        break;
      case "Escape":
        refreshTimeline();
        break;
      case "Space":
        e.stopPropagation();
        e.preventDefault();
        toggleTimeline(document.querySelector("#timeline_commands #tl_play"));
        break;
      default:
    }
  }, true);
  timeline.setUp = true;
}

function initMobileTimeline() {
  if (!timeline.timelineSlider) {
    timeline.timelineSlider = document.querySelector("#mobile_timeline input[type='range']");

    let timelineEntries = Object.keys(data.timelineData),
      currentStep = timelineEntries.length > 0 ? computeStep(parseInt(timelineEntries.pop())) : 0;
    // console.debug('currentStep : ' + currentStep);

    rangeSlider.create(timeline.timelineSlider, {
      polyfill: true,
      root: document,
      rangeClass: 'rangeSlider',
      disabledClass: 'rangeSlider--disabled',
      fillClass: 'rangeSlider__fill',
      bufferClass: 'rangeSlider__buffer',
      handleClass: 'rangeSlider__handle',
      startEvent: ['mousedown', 'touchstart', 'pointerdown'],
      moveEvent: ['mousemove', 'touchmove', 'pointermove'],
      endEvent: ['mouseup', 'touchend', 'pointerup'],
      vertical: false,
      value: currentStep,
      buffer: Math.round(currentStep * 100 / 144),
      borderRadius: 10,
      onInit: function () {
        timeline.timelinePosition = currentStep;
      },
      onSlideStart: function (position, value) {
        timeline.timelineActiveStep = timeline.timelineActiveStep || defaultActiveStep();
      },
      onSlide: function (position, value) {
        let offset = position - timeline.timelinePosition,
          newTs = timeline.timelineReference + Math.round(value * 144 * 600);
        if (offset) {
          selectTsTimeline(newTs, false);
          timeline.timelinePosition = position;
        }
      },
      onSlideEnd: function (position, value) {
        timeline.timelineActiveStep =
          document.querySelector("#timeline_content .tl_item[data-ts='" + (timeline.timelineReference + position * timeline.timelineStep) + "']");
      }
    });
  }
}

function updateMobileTimeline(newVal) {
  var lastValue = computeStep(parseInt(Object.keys(data.timelineData).pop() || timeline.timelineReference)),
    newValue = newVal ? computeStep(newVal) : lastValue,
    newBuffer = Math.round(lastValue * 100/144),
    newRangeValue = (timeline.timelineActiveStep && !newVal) ? {buffer: newBuffer} : {value: newValue, buffer: newBuffer};

  timeline.timelineSlider.rangeSlider.update(newRangeValue, false);
}

function computeStep(ts) {
  return (ts - timeline.timelineReference) / timeline.timelineStep;
}

