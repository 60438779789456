import Navigo from 'navigo';
import {sensorDetails} from "./sensor";
import {config, loadSensors} from "./config";
import {getAsync} from "./utils";
import {displayMeasures, displayWaterTemp, displayWaves, initMeasuresLayer, tideDetails, webcamDetails} from "./data";
import {setPoiButton, weatherDetails} from "./meteogramme";
import {displayNews, layers, logPageView, map, updateLayout} from "./common";
import {initTimeline} from "./timeline";
import {initMap} from "./map";

const router = new Navigo("/");
let autoRefresh;

router.on("/", function(match) {
  displayDefaultData();
  logPageView(router.lastResolved(), '/', 'Accueil');
  setAutoRefresh();
});

router.on("/webcam/:name", function (match) {
  displayDefaultData(function() {
    getAsync(config.backEndUrl + '/webcams/list.json', function (data) {
      var webcam = data.webcams.find(element => element.slug === match.data.name);
      if (webcam) {
        webcamDetails(webcam);
        logPageView(router.lastResolved(),'/webcam/' + webcam.slug, webcam.label);
      }
    });
  });
});

router.on("/port/:name", function (match) {
  displayDefaultData(function() {
    getAsync(config.backEndUrl + '/harbors/list.json', function (data) {
      var harbor = data.harbors.find(element => element.slug === match.data.name);
      if (harbor) {
        tideDetails(harbor);
        logPageView(router.lastResolved(),'/port/' + harbor.slug, harbor.label);
      }
    });
  });
});

router.on("/previsions/:name", function (match) {
  displayDefaultData(function() {
    getAsync(config.backEndUrl + "/live_forecasts/weather.json", function (poisData) {
      var poi = poisData["pois"].find(element => element.slug === match.data.name);
      if (poi) {
        weatherDetails(poi);
        var menuBtn = document.querySelector("#weather_fc");
        if (menuBtn.classList.contains("active") && layers.dataLayers.indexOf('weather_fc') !== -1) {
          setPoiButton(poi.slug);
        }
        map.instance.setView(new L.LatLng(parseFloat(poi.lat) - 0.1, parseFloat(poi.lng)), 9.5);
        logPageView(router.lastResolved(),'/previsions/' + poi.slug, poi.label);
        setAutoRefresh();
      }
    });
  });
});

router.on("/:name", function (match) {
  displayDefaultData(function () {
    loadSensors(function (data) {
      let sensors = Object.values(data.sensors['WindSensor'] || {}).concat(data.sensors['WaveSensor'] || {})
        .concat(data.sensors['TempSensor'] || []);
      let sensor = sensors.find(s => s.slug === match.data.name);
      if (sensor) {
        sensorDetails(sensor);
        logPageView(router.lastResolved(),'/' + sensor.slug, sensor.label);
        setAutoRefresh();
      }
    });
  });
});

if (config.favoritePath && window.location.pathname === '/') {
  if(document.querySelector("#map_favorite button").classList.contains("no-favorite")){
    document.querySelector("#map_favorite button").classList.remove("no-favorite")
  }
  router.navigate(config.favoritePath);
} else {
  if(!document.querySelector("#map_favorite button").classList.contains("no-favorite")){
    document.querySelector("#map_favorite button").classList.add("no-favorite")
  }
  router.resolve() ;
}

if (!config.newsDisplay && window.location.search.indexOf('nosplash') === -1) {
  displayNews(1000);
}

function displayDefaultData(callback) {
  let isHome = router.lastResolved() === null || router.lastResolved().path === '';
  if (isHome) {
    updateLayout();

    var referenceDate = new Date(new Date().toDateString()).getTime() / 1000;

    initTimeline(referenceDate, function() {
      initMap().then(function () {
        loadSensors(function () {
          initMeasuresLayer();
          displayMeasures();
          displayWaves();
          displayWaterTemp();
          if (callback) {
            callback();
          }
        });
      });
    });
  } else {
    displayMeasures();
    displayWaves();
    if (callback) {
      callback();
    }
  }
}

function setAutoRefresh() {
  if (autoRefresh) {
    clearTimeout(autoRefresh);
  }
  autoRefresh = setTimeout(function() {
    window.location.reload();
  }, 900000);
}

export default router;
